import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`ByCard: Removed custom sidebar implementation.`}</li>
      <li parentName="ul">{`Sidetalk: Replaced the current Tabs usage for the new @sidetalk/react Tabs component.`}</li>
      <li parentName="ul">{`ByCard: Not showing “Classify” button on automatic classified calls`}</li>
      <li parentName="ul">{`Engagement Studio: Removed “Global Settings” from Answering Mode in branches options`}</li>
      <li parentName="ul">{`ByCard: Show visitor information after replying to a schedule on outbound voice campaigns`}</li>
      <li parentName="ul">{`Audio App: Moved beyond business hours audio to “beyond business hours” separator *`}</li>
      <li parentName="ul">{`Bug Fix: Classification Fields on Aggregation Panels showing correct metrics.`}</li>
      <li parentName="ul">{`Bug Fix: It is possible to enter the cell phone number on the console.`}</li>
      <li parentName="ul">{`Bug Fix: Fixed sending messages in Team Chat.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      